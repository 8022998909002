import { Col } from 'components/grid'
import { AddOn } from 'pages/digital-solutions'
import React from 'react'

interface Props {
	addOn: AddOn
}

export default function AddOnCard({ addOn }: Props) {
	return (
		<Col width="w-full md:w-1/2 xl:w-1/4" className="flex mb-6 xl:mb-0">
			<div className="border shadow-xl rounded-xl flex flex-col w-full overflow-hidden">
				<div className="p-8 pb-4">
					<span className="text-center text-ab-100 font-light text-sm block">{addOn.heading}</span>
					<span className="font-bold text-2xl text-center block my-2">{addOn.subHeading}</span>
					<span className="font-light text-sm text-center block">{addOn.description}</span>
				</div>
				<hr />
				<div className="px-8 py-4">
					<ul className="list-checkcircle space-y-4 pl-4 text-sm">
						{addOn.list.map((item) => (
							<li key={item.title}>{item.title}</li>
						))}
					</ul>
				</div>
				<div className="h-1 arc-gradient mt-auto"></div>
			</div>
		</Col>
	)
}
