import { Col, Row } from 'components/grid'
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { twMerge } from 'tailwind-merge'

const tabStyles = 'pb-[2px] rounded-t-lg bg-gray-200 hover:bg-gray-300 min-w-max cursor-pointer flex-1'
const tabDivStyles = 'text-xl rounded-t-lg text-center px-3 py-2 bg-white'

export default function new_ForTabs() {
	return (
		<Tabs selectedTabClassName="arc-gradient">
			<TabList className="pb-20 pt-10 flex flex-wrap gap-4 px-4">
				<Tab className={tabStyles}>
					<div className={tabDivStyles}>For Teachers</div>
				</Tab>
				<Tab className={tabStyles}>
					<div className={tabDivStyles}>For Students</div>
				</Tab>
				<Tab className={tabStyles}>
					<div className={tabDivStyles}>For School Leaders</div>
				</Tab>
				<Tab className={tabStyles}>
					<div className={tabDivStyles}>For Families</div>
				</Tab>
			</TabList>
			<TabPanel>
				<TabContent slides={teacherSlides} />
			</TabPanel>
			<TabPanel>
				<TabContent slides={studentSlides} />
			</TabPanel>
			<TabPanel>
				<TabContent slides={leaderSlides} />
			</TabPanel>
			<TabPanel>
				<TabContent slides={homeSlides} />
			</TabPanel>
		</Tabs>
	)
}

interface SlideProps {
	slides: {
		title: string
		description: string
		id: string
	}[]
}

function TabContent({ slides }: SlideProps) {
	const [slide, setSlide] = React.useState(slides[0].id)
	return (
		<Row middle>
			<Col className="w-full lg:w-1/3 mb-4 lg:mb-0">
				<div className="flex flex-row lg:flex-col gap-4">
					{slides.map((s) => {
						return (
							<div key={s.id} className={twMerge('rounded-lg lg:rounded-l-lg p-[2px] bg-gray-200 flex-1 hover:bg-gray-300', s.id === slide && 'arc-gradient')}>
								<button
									onClick={() => setSlide(s.id)}
									className="flex border-r-0 gap-y-3 bg-white w-full flex-col p-2 lg:p-4 rounded-[calc(0.5rem-2px)] lg:rounded-l-[calc(0.5rem-2px)]"
								>
									<span className="text-center w-full lg:text-left block text-lg lg:text-xl font-semibold">{s.title}</span>
									<p className="hidden lg:block text-left text-sm">{s.description}</p>
								</button>
							</div>
						)
					})}
				</div>
			</Col>
			<Col className="w-full lg:w-2/3">
				<img src={`/images/digital-solutions/${slide}.png`} alt={slide} />
			</Col>
		</Row>
	)
}

const teacherSlides = [
	{
		title: 'Analytics',
		description:
			'Get real-time insights into each student’s learning needs with powerful classroom analytics. These data-driven insights enable educators to effortlessly identify the next steps for student growth.',
		id: 'analytics'
	},
	{
		title: 'Assessment',
		description:
			'Gain valuable insights into what each student needs to learn next with classroom assessments. These insights empower educators to tailor instruction effectively, ensuring that every student is on the path to success.',
		id: 'assessment'
	},
	{
		title: 'Lessons',
		description:
			'Access comprehensive digital teacher guides and curricular materials designed to support effective instruction. These resources empower educators to deliver engaging and impactful lessons.',
		id: 'lessons'
	}
]

const studentSlides = [
	{
		title: 'Assignments',
		description:
			'Empower students to complete assignments and access essential resources that support their knowledge-building core curriculum. These tools ensure that every learner is equipped to succeed.',
		id: 'student-lessons'
	},
	{
		title: 'Reading',
		description:
			'Encourage daily reading practice with a collection of engaging eBooks that students can and want to read, whether they’re at school, at home, or anywhere in between.',
		id: 'student-reading'
	},
	{
		title: 'Logging',
		description:
			'Students use ARC Reads, giving them access to interactive assignments, a digital logging solution, and tools to establish a baseline for their independent reading.',
		id: 'student-logging'
	},
	{
		title: 'Practice',
		description: 'Students play ARC Adventures, an educational video game designed to help students practice the 1G power words outlined in the IRLA.',
		id: 'student-practice'
	}
]

const leaderSlides = [
	{
		title: 'Analytics',
		description:
			'Empower school leaders with real-time insights into student data at both school and district levels. Dashboards track key metrics and drivers of success, enabling stakeholders to identify where support and intervention are needed most.',
		id: 'analytics'
	},
	{
		title: 'Curriculum',
		description:
			'Provide school leaders with clear insights into curriculum implementation across schools and districts. Track instructional effectiveness, identify areas for improvement, and ensure alignment with educational goals. Empower leaders to make informed decisions that drive academic success',
		id: 'reporting'
	}
]

const homeSlides = [
	{
		title: 'Home Updates',
		description: 'Home updates provide families with clear, user-friendly information (in English and Spanish) and the tools and resources to help their children learn.',
		id: 'home-updates'
	}
]
