import React, { ReactNode } from 'react'
import { FieldElement, FieldValues, Ref, RegisterOptions, useForm, useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

type InputBaseProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id' | 'label'>
type TextAreaBaseProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'id' | 'label'>
type SelectBaseProps = Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'id' | 'label'>
type ConditionalLabelId = { label: string; id: string } | { label?: never; id: string }
type ValidationWithOptional = { validation?(ref: (FieldElement<FieldValues> & Ref) | null): void; optional?: boolean }
type InputProps = InputBaseProps & ConditionalLabelId & ValidationWithOptional
type TextAreaProps = TextAreaBaseProps & ConditionalLabelId & ValidationWithOptional
type SelectProps = SelectBaseProps & ConditionalLabelId & ValidationWithOptional & { items: string[] }

export function Input({ label, validation, optional, ...props }: InputProps) {
	const { errors } = useFormContext()
	return (
		<>
			{label && <Label id={props.id} label={label} required={props.required} optional={optional} />}
			<input
				{...props}
				className={twMerge(
					'block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500',
					props.className,
					errors && errors[props.id] && 'border-red-400 focus:border-red-400 focus:ring-red-400'
				)}
				ref={validation}
				name={props.id}
			/>
			{errors && errors[props.id] && <ValidationError>{errors[props.id].message}</ValidationError>}
		</>
	)
}

export function TextArea({ label, validation, optional, ...props }: TextAreaProps) {
	const { errors } = useFormContext()
	return (
		<>
			{label && <Label id={props.id} label={label} required={props.required} optional={optional} />}
			<textarea
				{...props}
				className={twMerge(
					'block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500',
					props.className,
					errors && errors[props.id] && 'border-red-400 focus:border-red-400 focus:ring-red-400'
				)}
				ref={validation}
				name={props.id}
			/>
			{errors && errors[props.id] && <ValidationError>{errors[props.id].message}</ValidationError>}
		</>
	)
}

export function Select({ label, validation, items, optional, ...props }: SelectProps) {
	const { errors } = useFormContext()
	return (
		<>
			{label && <Label id={props.id} label={label} required={props.required} optional={optional} />}
			<select
				{...props}
				className={twMerge(
					'block w-full rounded-lg border border-gray-300 bg-gray-50 p-[14px] text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500',
					props.className,
					errors && errors[props.id] && 'border-red-400 focus:border-red-400 focus:ring-red-400'
				)}
				ref={validation}
				name={props.id}
			>
				<option></option>
				{items.map((i) => (
					<option key={i} value={i}>
						{i}
					</option>
				))}
			</select>
			{errors && errors[props.id] && <ValidationError>{errors[props.id].message}</ValidationError>}
		</>
	)
}

interface LabelProps {
	id?: string
	label: string
	required?: boolean
	optional?: boolean
}

export function Label({ id, label, required, optional }: LabelProps) {
	return (
		<label htmlFor={id} className="mb-2 block font-inter text-lg font-medium">
			{label}
			{required && <sup className="text-red-500">*</sup>}
			{optional && <span className="ml-2 text-sm text-gray-400">(optional)</span>}
		</label>
	)
}

interface ValidationErrorProps {
	children: ReactNode
}

export const ValidationError = ({ children }: ValidationErrorProps) => {
	return <span className="mt-1 block text-sm font-medium text-red-400">{children}</span>
}
